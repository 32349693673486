import { ApolloError } from "@apollo/client";
import {
  CREATE_PENDING_SESSION_KEY,
  UPDATE_REQUEST_SESSION_KEYS,
} from "core/apollo/graphql";
import { AnyObject, QueryProgress } from "core/types";
import { ReactNode, useCallback } from "react";
import { GenericUpdateMutation } from "./utils";

type QueryMutation = {
  children: (
    submit: (...args: any[]) => any,
    queryProgress: QueryProgress,
    resetProgress: () => void,
    error: ApolloError | undefined,
  ) => ReactNode | null;
  onCompleted?: (data: Readonly<any>) => void;
  onError?: (error: ApolloError | undefined) => void;
};

/** @deprecated To be removed when old encrytion gets decommissioned */
export const UpdateRequestSessionKeysMutation = ({
  auctionRequestId,
  children,
  onCompleted,
  onError,
}: QueryMutation & {
  auctionRequestId: number;
}) => {
  const getVariables = (newSessionKeys: AnyObject) => ({
    variables: {
      auctionRequestId,
      input: newSessionKeys,
    },
  });

  return (
    <GenericUpdateMutation
      getVariables={getVariables}
      mutation={UPDATE_REQUEST_SESSION_KEYS}
      onCompleted={onCompleted}
      onError={onError}
    >
      {children}
    </GenericUpdateMutation>
  );
};

export const CreatePendingSessionKeyMutation = ({
  accountId: account_id,
  auctionRequestId: auction_request_id,
  careproviderId: careprovider_id,
  careseekerId: careseeker_id,
  children,
  onCompleted,
  onError,
}: QueryMutation & {
  accountId: number | undefined;
  auctionRequestId: number | undefined;
  careproviderId: number | undefined;
  careseekerId: number | undefined;
}) => {
  const getVariables = useCallback(
    () => ({
      variables: {
        input: {
          auction_request_id,
          careprovider_id,
          careseeker_id,
          account_id,
        },
      },
    }),
    [],
  );

  return (
    <GenericUpdateMutation
      getVariables={getVariables}
      mutation={CREATE_PENDING_SESSION_KEY}
      onCompleted={onCompleted}
      onError={onError}
    >
      {children}
    </GenericUpdateMutation>
  );
};
